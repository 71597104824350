import invariant from 'invariant'

import { ConvertToFreeSessionRequest } from '@contracts/types/FunctionsGuestParkingTypes'
import { GuestParkingSession } from '@contracts/types/GuestParkingSession'

import { captureException } from '@guest-parking/Sentry'

import { convertToFreeSession, getActiveSessionForPlate, handleGuestParking } from './CloudFunctionsApiHandler'
import { upsertGuestParkingSession } from './DBApiHandler'
import { captureAndNotifyError } from './ErrorHelper'

export enum GuestParkingSteps {
  GUEST_PARKING_FORM = 'GUEST_PARKING_FORM',
  GUEST_PARKING_CONDITIONS = 'GUEST_PARKING_CONDITIONS',
  GUEST_PARKING_INFORMATION = 'GUEST_PARKING_INFORMATION'
}

export type GuestParkingStateData = GuestParkingSession

export type GuestParkingState = {
  data: GuestParkingStateData
  step: GuestParkingSteps
}

export type GuestParkingViewProps = {
  isLoading: boolean
  guestParkingState: GuestParkingState
  onPressContinue: (state: GuestParkingState) => Promise<unknown>
}

export const DEFAULT_GUEST_PARKING_STATE: GuestParkingState = {
  data: {
    session: undefined,
    siteId: '',
    name: '',
    companyName: '',
    phoneNumber: '',
    registrationNumber: '',
    permitId: '',
    uid: ''
  } as GuestParkingStateData,
  step: GuestParkingSteps.GUEST_PARKING_FORM
}

export const onPressContinue = (guestParkingState: GuestParkingState): Promise<GuestParkingState> =>
  Promise.resolve().then(async () => {
    switch (guestParkingState.step) {
      case GuestParkingSteps.GUEST_PARKING_FORM: {
        const drifterSession = await getActiveSessionForPlate({ plate: guestParkingState.data.registrationNumber })

        guestParkingState.data = { ...guestParkingState.data, session: drifterSession } as GuestParkingStateData

        if (!drifterSession) return { ...guestParkingState, step: GuestParkingSteps.GUEST_PARKING_FORM }
        invariant(guestParkingState.data.session?.id, 'drifterSession.id must be defined')

        const retrievedSessionsiteId = drifterSession.parkingSession?.siteId
        const guestParkingStateSessionSiteId = guestParkingState.data.session?.parkingSession?.siteId

        await upsertGuestParkingSession({
          ...guestParkingState.data,
          siteId: guestParkingState.data.siteId || retrievedSessionsiteId || guestParkingStateSessionSiteId
        }).catch((e) => {
          captureException(e, (scope) => {
            scope.addBreadcrumb({ message: `guestParkingState.data`, data: guestParkingState.data })
            scope.addBreadcrumb({ message: `guestParkingState.data.session`, data: guestParkingState.data.session })
            scope.addBreadcrumb({ message: `drifterSession`, data: drifterSession })

            scope.setTransactionName('GuestParkingHelper--upsertGuestParkingSession')

            return scope
          })
        })

        return { ...guestParkingState, step: GuestParkingSteps.GUEST_PARKING_CONDITIONS }
      }

      case GuestParkingSteps.GUEST_PARKING_CONDITIONS: {
        console.debug('GuestParkingHelper.ts:64 -- guestParkingState.data', guestParkingState.data)

        return Promise.resolve()
          .then(async () => {
            const sessionId = guestParkingState.data.session?.id?.toString()
            const freeGuestParkingToken = guestParkingState.data.freeGuestParkingToken
            invariant(sessionId, 'sessionId must be defined')
            invariant(freeGuestParkingToken, 'freeGuestParkingToken must be defined')

            let session = guestParkingState.data.session

            if (guestParkingState.data.permitId) {
              const {
                data: { uid = '', registrationNumber, freeGuestParkingToken = '' }
              } = guestParkingState

              /**
               * This feature is not live yet, and no one has permitId's yet.
               * TODO: Might need to return session as well like convertToFreeSession does
               */
              await handleGuestParking({ uid, plates: [registrationNumber], freeGuestParkingToken })
            } else {
              session = await convertToFreeSession({ sessionId, freeGuestParkingToken } as ConvertToFreeSessionRequest)
            }

            return {
              ...guestParkingState,
              step: GuestParkingSteps.GUEST_PARKING_INFORMATION,
              data: { ...guestParkingState.data, session }
            }
          })
          .catch((e) => {
            captureAndNotifyError(e)
            return { ...guestParkingState, step: GuestParkingSteps.GUEST_PARKING_CONDITIONS }
          })
      }
      default:
        throw new Error(`Unknown step: ${guestParkingState.step}`)
    }
  })

export function normaliseRegistrationNumber(registrationNumber: string) {
  return registrationNumber.trim().replace(/\s/g, '').toUpperCase()
}
