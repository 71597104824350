import { toast } from 'react-toastify'

import onUnhandledPromiseRejection from './onUnhandledPromiseRejection'
import { getFigmaTextFromVariable } from './TextRepository'
import { Variables } from './Variables'

const SOMETHING_WENT_WRONG = getFigmaTextFromVariable(Variables['VariableID:86:763'])

export const captureAndNotifyError = (err: Error, text?: string) => {
  showGenericErrorToast(text)
  onUnhandledPromiseRejection(err)
}

export function showGenericErrorToast(text = SOMETHING_WENT_WRONG) {
  toast(text, {
    type: 'error',
    position: 'top-center'
  })
}
