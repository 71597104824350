const env: string | undefined = (import.meta as any).env?.VITE_ENVIRONMENT

export const defaultConfig = {
  apiUrl: 'https://app.drifterworld.se',
  sentryDsn: 'https://68f3b59c939858809ff5bfca90d9921c@o4504077826981888.ingest.sentry.io/4506669733314560',
  payBaseUrl: 'https://pay.drifterworld.se',
  HASH_KEY: 'drifterworldabv1',
  depreceatedStripePriceId: 'price_1O78RHLxoUxL65YUNcB6kPed',
  enableSentry: false,
  enableLogFirestoreQueryResult: false,
  enableSandbox: false,
  enableSlack: false,
  disableHashReceiptCheck: false,
  enableShowFreeSessions: false,
  enableExperimentalLogger: true,
  enableDevToken: false,
  enableAlwaysShowForm: false
}

export type ConfigType = typeof defaultConfig

export const local: ConfigType = {
  ...defaultConfig,
  enableDevToken: true,
  enableSentry: false,
  // apiUrl: 'https://apptest.drifterworld.se',
  payBaseUrl: typeof window !== 'undefined' ? window.location.origin : 'http://localhost:5173',
  disableHashReceiptCheck: true,
  enableShowFreeSessions: true,
  enableSlack: false,
  enableAlwaysShowForm: true
}

export const prod: ConfigType = {
  ...defaultConfig,
  enableSentry: true,
  enableSlack: true
}

export const config =
  {
    local,
    prod
  }[env || 'prod'] || prod

export default config
