import styled from '@emotion/styled'
import { Button as MuiButton } from '@mui/material'
import { Controller } from 'react-hook-form'
import * as Yup from 'yup'

import { Spacings } from '@guest-parking/enums/Spacings'
import { useForm } from '@guest-parking/hooks/useForm'
import Images from '@guest-parking/libs/Images'
import { useSiteForWindow } from '@guest-parking/libs/ReactQueryHooks'
import { getFigmaTextFromVariable } from '@guest-parking/libs/TextRepository'
import Texts from '@guest-parking/libs/Texts'
import { RequiredTextValidation } from '@guest-parking/libs/ValidationHelper'
import { Variables } from '@guest-parking/libs/Variables'
import { SignInViewProps } from '@guest-parking/types/SignInTypes'

import Box from './Box'
import Button from './Button'
import FigmaImage from './FigmaImage'
import FigmaText from './FigmaText'
import { GuestParkingSiteLogo } from './GuestParkingSiteLogo'
import TextFieldForm from './TextFieldForm'
// import { useGetGuestLogo } from '../../../web-js/src/libs/ReactQueryHooks'

const LoginEnterCode = (props: SignInViewProps) => {
  const site = useSiteForWindow(window)
  const validationSchema = Yup.object().shape({
    code: RequiredTextValidation('Enter SMS code')
  })

  const formProps = useForm(validationSchema, { defaultValues: { code: '' } })

  const _onPressContinue = (values: string) => {
    return props
      .onPressContinue({ ...props.signInState, data: { ...props.signInState.data, code: values } })
      .then((res) => {
        console.log(res)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onSubmit = () => {
    if (!formProps.getValues('code')) return
    _onPressContinue(formProps.getValues('code'))
  }

  return (
    <Box fullWidth fullHeight align="center" top spacing={Spacings.xxxl}>
      <GuestParkingSiteLogo site={site} />
      <StyledBox direction="row" align="center" justify="space-between" top left right bottom spacing={Spacings.xs}>
        <FigmaImage imageKey={Images.checkIconOutlined} />
        <TextContainer left right spacing={Spacings.xs}>
          <FigmaText textKey={Texts.textNotificationsNotificationGuestParkingSentCode} />
        </TextContainer>
        <MuiButton autoCapitalize="false" onClick={props.onPressBack}>
          <FigmaText textKey={Texts.supportTextsSupportTextWeb3} text="Back" />
        </MuiButton>
      </StyledBox>
      <Container fullWidth top>
        <FigmaText
          textKey={Texts.textPaymentsQrCodePaymentRegNoHelperText}
          text={getFigmaTextFromVariable(Variables['VariableID:1274:12272'])}
        />
        <form onSubmit={formProps.handleSubmit(onSubmit)}>
          <Box fullWidth top bottom spacing={Spacings.s}>
            <Controller
              control={formProps.control}
              name="code"
              render={({ field, fieldState }) => (
                <TextFieldForm
                  {...field}
                  value={field.value.toLocaleUpperCase()}
                  fullWidth
                  fieldState={fieldState}
                  autoFocus
                  placeholder="Code"
                />
              )}
            />
          </Box>
          <Button
            fullWidth
            text={getFigmaTextFromVariable(Variables['VariableID:362:6744'])}
            type="submit"
            loading={props.isLoading}
          />
        </form>
      </Container>
    </Box>
  )
}

const StyledBox = styled(Box)`
  max-width: 400px;
  border: 2px solid green;
  border-radius: 10px;
  margin-top: ${Spacings.s};
`

const TextContainer = styled(Box)`
  max-width: 260px;
`

const Container = styled(Box)`
  max-width: 350px;
`

export default LoginEnterCode
