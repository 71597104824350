import { collection, doc, getDocs, setDoc } from 'firebase/firestore'

import Collections, { CollectionGroups } from '@contracts/enums/Collections'
import { GuestParkingSession } from '@contracts/types/GuestParkingSession'
import { HikerUser } from '@contracts/types/HikerUser'

import { createUniqueId } from '@pure/libs/Common'

import { db } from './FirebaseOptions'

export const upsertGuestParkingSession = async (p: GuestParkingSession): Promise<any> =>
  setDoc(
    doc(
      db,
      `${Collections.SITES}/${p.siteId}/${CollectionGroups.GUEST_PARKING_SESSIONS}`,
      p.session?.id?.toString() || createUniqueId()
    ),
    p
  ).then(() => p)

export const getUserByPhoneNumber = async (phoneNumber?: string | null) => {
  const userQuery = await getDocs(collection(db, Collections.USERS))
  const users = userQuery.docs.map((doc) => doc.data() as HikerUser)

  return users.find((user) => user.phone === phoneNumber)
}
