import { Site } from '@contracts/types/Site'

import Images from '@guest-parking/libs/Images'

import FigmaImageContainer from './FigmaImageContainer'

export const GuestParkingSiteLogo = ({ site }: { site?: Site }) => (
  <>
    {site?.logo && <img src={site.logo} />}
    {!site?.logo && <FigmaImageContainer imageKey={Images.drifterLogoDark} width={300} />}
  </>
)
