import invariant from 'invariant'
import useQueryParams from 'pure/libs/useQueryParams'
import { useEffect, useState } from 'react'

import { GuestParkingFeatureQueryParams } from '@contracts/types/GuestParkingFeatureQueryParams'

import useAppState from '@guest-parking/hooks/useAppState'
import { useFreeGuestParkingTokenPayload } from '@guest-parking/hooks/useFreeGuestParkingToken'
import {
  DEFAULT_GUEST_PARKING_STATE,
  GuestParkingState,
  GuestParkingSteps,
  GuestParkingViewProps,
  onPressContinue
} from '@guest-parking/libs/GuestParkingHelper'
import onUnhandledPromiseRejection from '@guest-parking/libs/onUnhandledPromiseRejection'
import { captureException } from '@guest-parking/Sentry'

import GuestParkingConditions from './GuestParkingConditions'
import GuestParkingForm from './GuestParkingForm'
import GuestParkingInformation from './GuestParkingInformation'

export const GuestParkingViews = {
  [GuestParkingSteps.GUEST_PARKING_FORM]: GuestParkingForm,
  [GuestParkingSteps.GUEST_PARKING_CONDITIONS]: GuestParkingConditions,
  [GuestParkingSteps.GUEST_PARKING_INFORMATION]: GuestParkingInformation
}

export const GuestParkingMachine = () => {
  const Views = GuestParkingViews
  const [isLoading, setIsLoading] = useState(false)
  const { freeGuestParkingToken } = useQueryParams<GuestParkingFeatureQueryParams>()
  const { siteId, permitId } = useFreeGuestParkingTokenPayload() || {}

  const { state } = useAppState()

  const [guestParkingState, setGuestParkingState] = useState<GuestParkingState>({
    ...DEFAULT_GUEST_PARKING_STATE,
    data: { ...DEFAULT_GUEST_PARKING_STATE.data, freeGuestParkingToken }
  })

  useEffect(() => {
    if (state?.firebaseUser?.phoneNumber && state.firebaseUser.uid) {
      setGuestParkingState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          phoneNumber: state.firebaseUser?.phoneNumber || '',
          uid: state.firebaseUser?.uid || ''
        }
      }))
    }
  }, [state.firebaseUser])

  useEffect(() => {
    if (siteId && permitId) {
      setGuestParkingState((prevState) => ({
        ...prevState,
        data: { ...prevState.data, siteId, permitId }
      }))
    }
  }, [siteId, permitId])

  const guestParkingViewProps: GuestParkingViewProps = {
    isLoading,
    guestParkingState,
    onPressContinue: (guestParkingState) =>
      Promise.resolve(setIsLoading(true))
        .then(() => onPressContinue(guestParkingState))
        .then((guestParkingState: GuestParkingState) => setGuestParkingState(guestParkingState))
        .catch((e) => {
          captureException(e)
          onUnhandledPromiseRejection(e)
        })
        .finally(() => setIsLoading(false))
  }

  const Component: React.FC<GuestParkingViewProps> = Views[guestParkingViewProps.guestParkingState.step]
  invariant(Component, `Can't find step for %s`, guestParkingViewProps.guestParkingState.step)

  return <Component {...guestParkingViewProps} />
}
