import dayjs from 'dayjs'

import { GuestParkingMachine } from '@guest-parking/components/GuestParkingMachine'
import config from '@guest-parking/Config'
import { useFreeGuestParkingTokenPayload } from '@guest-parking/hooks/useFreeGuestParkingToken'

import { GuestParkingInvalidTokenScreen } from './GuestParkingInvalidTokenScreen'

export const GuestParkingScreen = () => {
  const freeGuestParkingToken = useFreeGuestParkingTokenPayload()

  if (
    (!config.enableAlwaysShowForm && !dayjs(freeGuestParkingToken?.date).isSame(dayjs(), 'day')) ||
    !freeGuestParkingToken
  )
    return <GuestParkingInvalidTokenScreen />

  return <GuestParkingMachine />
}
