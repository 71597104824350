import { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import * as Yup from 'yup'

import { GuestParkingFormValues } from '@contracts/types/GuestParkingSession'

import { Spacings } from '@guest-parking/enums/Spacings'
import { useForm } from '@guest-parking/hooks/useForm'
import { useFreeGuestParkingTokenPayload } from '@guest-parking/hooks/useFreeGuestParkingToken'
import { GuestParkingViewProps, normaliseRegistrationNumber } from '@guest-parking/libs/GuestParkingHelper'
import { useSite } from '@guest-parking/libs/ReactQueryHooks'
import { getFigmaTextFromVariable } from '@guest-parking/libs/TextRepository'
import Texts from '@guest-parking/libs/Texts'
import { PhoneRegExp, RequiredTextValidation } from '@guest-parking/libs/ValidationHelper'
import { Variables } from '@guest-parking/libs/Variables'

import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import { GuestParkingFormInfoBox } from './GuestParkingFormInfoBox'
import { GuestParkingSiteLogo } from './GuestParkingSiteLogo'
import { HomeErrorMessage } from './HomeErrorMessage'
import { Layout } from './Layout'
import TextFieldForm from './TextFieldForm'

const GuestParkingForm = (props: GuestParkingViewProps) => {
  const { guestParkingState } = props
  const { session: drifterSession } = guestParkingState.data
  const payload = useFreeGuestParkingTokenPayload(guestParkingState.data.freeGuestParkingToken)
  const { data: site } = useSite(payload?.siteId ?? drifterSession?.parkingSession?.siteId ?? '')
  const formProps = useForm<GuestParkingFormValues>(ValidationSchema, {
    defaultValues: { name: '', companyName: '', phoneNumber: '', registrationNumber: '' }
  })

  useEffect(() => {
    if (guestParkingState) {
      formProps.setValue('phoneNumber', guestParkingState.data.phoneNumber)
      formProps.setValue('name', guestParkingState.data.name)
    }
  }, [guestParkingState])

  const sessionSiteId = guestParkingState.data.session?.parkingSession?.siteId

  const onSubmit = (values: GuestParkingFormValues) =>
    props.onPressContinue({
      ...props.guestParkingState,
      data: { ...guestParkingState.data, ...values, siteId: guestParkingState.data.siteId || sessionSiteId }
    })

  return (
    <Layout>
      <Box fullWidth top left right spacing={Spacings.xl}>
        <Box fullWidth align="center">
          <GuestParkingSiteLogo site={site} />
        </Box>
        <Box fullWidth align="center" top bottom spacing={Spacings.l}>
          <FigmaText textKey={Texts.textActiveSessionGuestParkingGuestParkingQrLandingHeader} />
        </Box>
        <form onSubmit={formProps.handleSubmit(onSubmit)}>
          <Box fullWidth bottom spacing={Spacings.l}>
            <FigmaText
              textKey={Texts.textPaymentsQrCodePaymentRegNoHelperText}
              text={getFigmaTextFromVariable(Variables['VariableID:1274:12246'])}
            />
            <Controller
              control={formProps.control}
              name="name"
              render={({ field, fieldState }) => (
                <TextFieldForm
                  {...field}
                  value={field.value}
                  fullWidth
                  fieldState={fieldState}
                  autoFocus
                  placeholder="Anders Andersson"
                />
              )}
            />
          </Box>
          <Box fullWidth bottom spacing={Spacings.l}>
            <FigmaText
              textKey={Texts.textPaymentsQrCodePaymentRegNoHelperText}
              text={getFigmaTextFromVariable(Variables['VariableID:1274:12247'])}
            />
            <Controller
              control={formProps.control}
              name="companyName"
              render={({ field, fieldState }) => (
                <TextFieldForm
                  {...field}
                  value={field.value}
                  fullWidth
                  fieldState={fieldState}
                  placeholder="Company Name"
                />
              )}
            />
          </Box>
          {!guestParkingState?.data?.phoneNumber && (
            <Box fullWidth bottom spacing={Spacings.l}>
              <FigmaText
                textKey={Texts.textPaymentsQrCodePaymentRegNoHelperText}
                text={getFigmaTextFromVariable(Variables['VariableID:1274:12248'])}
              />
              <Controller
                control={formProps.control}
                name="phoneNumber"
                render={({ field, fieldState }) => (
                  <TextFieldForm {...field} value={field.value} fullWidth fieldState={fieldState} placeholder="07-" />
                )}
              />
            </Box>
          )}
          <Box fullWidth bottom spacing={Spacings.l}>
            <FigmaText
              textKey={Texts.textPaymentsQrCodePaymentRegNoHelperText}
              text={getFigmaTextFromVariable(Variables['VariableID:527:8197'])}
            />
            <Controller
              control={formProps.control}
              name="registrationNumber"
              render={({ field, fieldState }) => (
                <TextFieldForm
                  {...field}
                  value={field.value.toUpperCase()}
                  fullWidth
                  fieldState={fieldState}
                  placeholder="ABC 123"
                />
              )}
            />
            {drifterSession === null && (
              <Box top spacing={Spacings.xs}>
                <HomeErrorMessage
                  textKey={Texts.textPaymentsPaymentSwishNumberErrorNotConnected}
                  text={getFigmaTextFromVariable(Variables['VariableID:1274:12256'])}
                />
              </Box>
            )}
          </Box>
          <Button
            fullWidth
            text={getFigmaTextFromVariable(Variables['VariableID:362:6744'])}
            type="submit"
            loading={props.isLoading}
          />
        </form>
        <Box fullWidth top bottom spacing={Spacings.xxl}>
          <GuestParkingFormInfoBox />
        </Box>
      </Box>
    </Layout>
  )
}

const ValidationSchema = Yup.object().shape({
  name: RequiredTextValidation('Enter Name'),
  companyName: Yup.string(),
  phoneNumber: Yup.string().matches(PhoneRegExp, 'Phone Number is NOT valid').required('Phone Number is required'),
  registrationNumber: RequiredTextValidation('Enter Registration Number').transform(normaliseRegistrationNumber)
})

export default GuestParkingForm
