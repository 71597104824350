import stringify from 'fast-safe-stringify'

import { SlackWebhooks } from '@contracts/enums/SlackWebhooks'

import { slack } from '@web-js/libs/SlackHelperClient'

export default async function onUnhandledPromiseRejection(error: Error) {
  if (!error?.message) return slack(`Unhandled promise rejection: ${stringify(error)}`, SlackWebhooks.OPS_APP_ERRORS)
  await slack(`${error.name} ${error.message} ${error.stack}`, SlackWebhooks.OPS_APP_ERRORS)
  return
}
